@font-face {
  font-family: "Nexa";
  src: url('resources/font/Nexa-Heavy.ttf'); 
}

* {
  font-family: "Nexa";
}

.App-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
}

.custom-card {
  padding: 25px;
}

.title h1 {
  color: white;
  font-size: 75px;
}

.custom-card img {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.custom-card:hover img {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
}

.custom-card img {
  transition: opacity 0.2s ease;
}

.custom-card:hover img {
  opacity: 0.3;
}

.card-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-card:hover .card-info {
  opacity: 1;
}

.card-info {
  cursor: pointer;
}

.card-info h5 {
  color: white;
  margin-bottom: 10px;
}

.download-btn {
  background-color: #1DB954;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #14803a;
}

/* Responsive adjustments for mobile phones */
@media (max-width: 768px) {
  .title h1 {
    font-size: 45px;
  }

  .App-container {
    padding: 15px;
  }

  .custom-card {
    padding: 15px;
  }

  .custom-card img {
    width: 100%;
  }

  .search-input-group {
    width: 90% !important;
  }

  .suggestions-list {
    width: 85% !important;
    top: 80%;
  }

  .custom-card {
    padding: 10px;
    margin-bottom: 15px;
  }

  .footer {
    font-size: 14px;
  }


}

@media (max-width: 480px) {
  .title h1 {
    font-size: 35px;
  }

  .custom-card {
    padding: 10px;
  }

  .search-input-group {
    width: 100% !important;
  }

  .suggestions-list {
    width: 95% !important;
    top: 85%;
  }

  .custom-card {
    padding: 8px;
  }

  .footer {
    font-size: 12px;
  }

}

.clear-btn {
  position: absolute;
  right: 80px; /* Adjust to align correctly */
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px;
  color: gray;
  cursor: pointer;
  padding: 0 5px;
  user-select: none;
}
